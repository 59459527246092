import React from 'react'
import Layout from '../../components/Layout'
import * as styles from './company.module.less'

const Company = () => {
  return (
    <Layout>
      <h1>Реквизиты</h1>
      <div className={styles.requisites}>
        {requisites.map(({ title, value }) => (
          <div className={styles.row} key={title}>
            <div className={styles.title}>{title}</div>
            <div className={styles.value}>{value}</div>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default Company

const requisites = [
  {
    title: 'Наименование организации',
    value: 'ООО «ФинМаркет»',
  },
  {
    title: 'ИНН',
    value: '7703461382',
  },
  {
    title: 'КПП',
    value: '770301001',
  },
  {
    title: 'Генеральный  директор',
    value: 'Бажутина Ольга Владимировна',
  },
  {
    title: 'Юридический адрес',
    value: `123112, город Москва, Пресненская набережная, дом 6, строение 2, этаж 36, помещение 3624`,
  },
  {
    title: 'Сфера деятельности',
    value:
      'Информационные технологии по коду 12.01, согласно Приказу Минцифры от 08.10',
  },
  {
    title: 'Примечание',
    value: 'Компания является резидентом проекта «Сколково»',
  },
]
